
                                        @import "~coreScss/variables/global-variables.scss";
                                        @import "~coreScss/mixins/global-mixins.scss";
                                    

    .c-loading-button {
        &__icon {
            margin-top: 2px;
        }
    }
